<template>
	<w-text-input
		v-model="copiedValue"
        :disabled-rules="['url']"
		inputmode="url"
        :prefix="prefix"
		:rules="inputRules"
		type="url"
		v-bind="$attrs"
	/>
</template>

<script>
import Validator from '@/mixins/Validator'

/**
 * @displayName w-url-input
 */
export default {
	name: 'WUrlInput',
	mixins: [Validator],
	props: {
        prefix: {
            default: undefined,
            required: false,
            type: String
        },
		value: {
			required: false,
			type: String,
			default: ''
		}
	},
	computed: {
		copiedValue: {
			get: function () {
                if (this.value && this.prefix && this.value.startsWith(this.prefix)) {
                    const count = this.prefix.length
                    return this.value.substring(count)
                } else {
				    return this.value
                }
			},
			set: function (val) {
                let url = val
                if (this.prefix && val) {
                    url = this.prefix + val
                }
				this.$emit('input', url)
			}
		},
		inputRules: function () {
			let result = []
            if (this.prefix) {
                result.push(function (value) {
                    let url = value
                    if (url) {
                        url = this.prefix + value
                    }
                    return this.rules.url(url)
                }.bind(this))
            } else {
                result.push(this.rules.url)
            }
            
			return result
		},
	}
}
</script>
